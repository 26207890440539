/* finalReviewer css */
form.albumReview {

    width: 80%;
    margin: auto;
    
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 10px;
}