/* reviewer.css */

section.reviewer {
    padding: 40px;
    margin: auto;
    max-width: 600px;
}

form.songReview {
    margin: auto;
    max-width: 500px;

    display: flex;
    flex-direction: column;
    gap: 10px;
}

label.ratingLabel {
    padding-right: 10px;
}
textarea.review {
    min-height: 200px;
    border: none;
    background-color: #012615;
    resize: none;
    padding: 10px;
    line-height: 1.4;

    color: #B0C6D9;
}
textarea::placeholder {
    color: #B0C6D9;
}

button.newQuoteBtn {
    width: 50px;
}