.App {
  text-align: center;
  height: 100%;
  width: 100%;
}

nav.hero {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Namaku;
}

nav.hero > a > h3 {
  color: #B0C6D9;
  font-size: 3em;
  padding: 0px;
  margin: 0px;
}

nav.hero > a:visited, nav.hero > a:active, nav.hero > a:link {
  text-decoration: none;
  color: #B0C6D9;
}

.btn {
  background-color: #243340;
  padding: 5px 5px;
  border-radius: 5px;
}

a.genLink:visited, a.genLink:active, a.genLink:link {
  text-decoration: none;
  color: #B0C6D9;
}


/* fonts */
@font-face {
  font-family: Namaku;
  src: url('./css/Namaku.ttf');
}

@media screen and (min-width: 700px) {
  nav.hero > a > h3 {
    font-size: 5em;
  }
}