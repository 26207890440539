div.pendingContentBlock{
    background: linear-gradient(to bottom right, #B0C6D9, #5D6973);
    border-radius: 10px;
    background-size: 200% 200%;

    -webkit-animation: Animation 2s ease infinite;
    -moz-animation: Animation 2s ease infinite;
    animation: Animation 2s ease infinite;
}
@-webkit-keyframes Animation {
    0%{background-position:0% 0%}
    50%{background-position:91% 100%}
    100%{background-position:0% 0%}
}
@-moz-keyframes Animation {
    0%{background-position:10% 0%}
    50%{background-position:91% 100%}
    100%{background-position:10% 0%}
}
@keyframes Animation { 
    0%{background-position:0% 0%}
    50%{background-position:91% 100%}
    100%{background-position:0% 0%}
}

section.albumReviews {
    padding: 20px;
    padding-top: 0px;
    color: #B0C6D9;

    display: flex;
    flex-direction: column;
}
article.albumReview {
    display: grid;
    grid-template-areas:    "albumInfo"
                            "albumCover" 
                            "albumReleaseDate"
                            "albumDescription" 
                            "albumLinks";
    grid-gap: 10px;
    font-family: sans-serif;

    border: 2px solid #B0C6D9;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
}
div.albumInfo {
    grid-area: albumInfo;
    display: flex;
    justify-content: space-around;
    align-items: center;
}
span.albumRating {
    border-radius: 50px;
    text-align: center;
    padding: 5px 10px;
    background-color: #070C0D;
}
a.albumCover {
    grid-area: albumCover;
    display: block;
    justify-content: space-evenly;
    align-items: center;
}
img.albumCover {
    height: auto;
    width: 100%;
    display: block;
    margin: auto;
}
section.albumLinks {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
}
section.albumLinks > a {
    display: flex;
    align-items: center;
    gap: 5px;
}
h3.releaseDate {
    margin: 0px;
}
p.content {
    margin: 0px;
    line-height: 1.4;
}

/* ipad design */
@media screen and (min-width: 700px) {
    article.albumReview  {
        max-width: 900px;
        margin: auto;

        grid-template-columns: 300px auto;
        grid-template-rows: auto 300px 20px auto auto auto ;
        grid-template-areas:
        "albumInfo albumInfo"
        "albumCover reviewContent"
        "albumReleaseDate reviewContent"
        "albumLinks reviewContent"
        "trackListHeader trackListHeader"
        "trackList trackList";
    }

    a.albumCover {
        height: 100%;
    }

    h3.releaseDate {
        grid-area: albumReleaseDate;
    }

    p.content {
        grid-area: reviewContent;
    }

    h3.trackListHeader{
        grid-area: trackListHeader;
    }

    section.trackList {
        grid-area: trackList;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    iframe.albumTrack {
        max-width: 49%;
    }

    h3.albumLinksHeader {
        display: none;
    }

    section.albumLinks {
        grid-area: albumLinks;
        justify-content: flex-start;
    }
    section.albumLinks > a {
        height: 25px;
    }
}