html {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#012615, #070C0D);
  overflow-y: hidden;
  color: #B0C6D9;
  font-family: sans-serif;
}
body {
  margin: 0;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(#012615, #070C0D);
}
div#root {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
